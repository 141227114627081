/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Button from "../Button";
import style from "./index.module.scss";
import Prev from "./prev.svg";
import Next from "./next.svg";

export const Pagination = (props) => {
  const { numberOfPages, nextPage, currentPage } = props;

  const getPageNumbers = () => {
    const pageNumbers = [];

    // Display the first three pages
    for (let i = 1; i <= Math.min(numberOfPages, 3); i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis (...) if there are more than 3 pages
    if (numberOfPages > 3) {
      if (currentPage > 3) {
        pageNumbers.push(currentPage);
      }
      pageNumbers.push("...");
    }

    // Display the last three pages
    for (let i = Math.max(numberOfPages - 2, 4); i <= numberOfPages; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className={style["pagination__container"]}>
      <Button
        disabled={currentPage === 1}
        className="fllled"
        onClick={() => nextPage(currentPage - 1)}
      >
        <p>Prev ←</p>
      </Button>
      <ul className={style["pagination__container--row"]}>
        {pageNumbers.map((iten, index) => (
          <li
            className={iten === currentPage ? style["active"] : ""}
            key={index}
            onClick={() => nextPage(iten)}
          >
            <a href="#">{iten}</a>
          </li>
        ))}
      </ul>
      <Button
        disabled={currentPage === numberOfPages}
        className="next"
        onClick={() => nextPage(currentPage + 1)}
      >
        <p>Next →</p>
      </Button>
    </div>
  );
};

export const BlogPagination = (props) => {
  const { numberOfPages, nextPage, currentPage } = props;
  const paginationLinks = [];

  for (let i = 1; i <= numberOfPages; i++) {
    let active = currentPage === i ? style["active"] : "";

    paginationLinks.push(
      <li className={active} key={i} onClick={() => nextPage(i)}>
        <a href="#">{i}</a>
      </li>
    );
  }

  return (
    <div className={style["blog--pagination__container"]}>
      <div
        disabled={currentPage === 1}
        className={style["prev--button"]}
        onClick={() => nextPage(currentPage - 1)}
      >
        <a href="#">
          <img src={Prev} alt="prev" />
        </a>
      </div>
      <ul className={style["pagination--row"]}>{paginationLinks}</ul>
      <div
        disabled={currentPage === numberOfPages}
        className={style["next--button"]}
        onClick={() => nextPage(currentPage + 1)}
      >
        <a href="#">
          <img src={Next} alt="next" />
        </a>
      </div>
    </div>
  );
};
