import React from "react";
import style from "./index.module.scss";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import NewMessage from "../../../assets/images/about/new-message.svg";
import CustomerInsight from "../../../assets/images/about/customer-insight.svg";
import OTP from "../../../assets/images/about/otp_ico.svg";

import Ugochukwu from "../../../assets/images/about/Ugochukwu.png";
import Goodnews from "../../../assets/images/about/Goodnews.png";
import Joshua from "../../../assets/images/about/Joshua.png";
import Adetokunbo from "../../../assets/images/about/Adetokunbo.png";
import Oluwatooni from "../../../assets/images/about/Oluwatooni.png";
import Osa from "../../../assets/images/about/Osa.png";
import Olubunmi from "../../../assets/images/about/Olubunmi.png";
import axios from "../../../utils/axios-base";
import CountUp from "react-countup";

export default function About(props) {
  const aboutTop = React.useRef(null);

  const [metrics, setMetrics] = React.useState(null);

  React.useEffect(() => {
    aboutTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    const getMetrics = async () => {
      try {
        const res = await axios.get("/social-proof");

        if (res.status === 200) {
          setMetrics(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMetrics();

    // setInterval(() => {
    //   getMetrics();
    // }, 20000);
  }, []);

  const metricsArray = [
    {
      icon: NewMessage,
      number: "50,000+",
      text: "Business messages sent",
    },
    {
      icon: CustomerInsight,
      number: "100+",
      text: "Businesses served",
    },
    {
      icon: OTP,
      number: "4,000+",
      text: "OTP transactions completed",
    },
  ];
  const members = [
    {
      name: "Ugochukwu Okoro",
      position: "CEO / Co-Founder",
      image: Ugochukwu,
    },
    {
      name: "Goodnews George",
      position: "Business Development",
      image: Goodnews,
    },
    {
      name: "Joshua Olatunde",
      position: "CTO",
      image: Joshua,
    },

    {
      name: "Adetokunbo Adebiyi",
      position: "Customer Success Executive",
      image: Adetokunbo,
    },
    {
      name: "Oluwatooni Olaifa",
      position: "Customer Success Associate",
      image: Oluwatooni,
    },
    {
      name: "Osa Emman-Ugheoke",
      position: "Product Manager",
      image: Osa,
    },
    {
      name: "Olubunmi Oladinni",
      position: "Digital Marketing",
      image: Olubunmi,
    },
  ];

  return (
    <>
      <div ref={aboutTop}></div>
      <Header theme="lightTheme" bgStyle="about__page--bg">
        <div className={style["header__details"]}>
          <div>
            <h3>Our Story</h3>
            <p>
              MyServiceAgent was first created to tackle the COVID-19 pandemic
              by helping the NCDC manage as many as 1000+ calls from individuals
              seeking information and care. Since then, we have realized that
              communication in general is a major problem for entities looking
              to engage with a mass population.
            </p>

            <p>
              Hence, we improved MyServiceAgent to better solve messaging and
              authentication challenges for businesses, with solutions such as
              MSA OTP Authentication, and MSA Business Messaging. Now, the
              entire MyServiceAgent suite is available to all forward-thinking
              businesses in Africa.
            </p>
          </div>
          {/* <img src={headerAbout} alt="faq" /> */}
        </div>
      </Header>

      <div className={style["container"]}>
        <div className={style["metrics--section"]}>
          <h3 className={style["metrics--section--title"]}>
            A look at the numbers we’ve done so far
          </h3>
          <div className={style["metric__items"]}>
            {metricsArray.map((item, index) => (
              <div className={style["metric__item"]} key={index}>
                <div className={style["icon"]}>
                  {" "}
                  <img src={item.icon} alt={item.text} />
                </div>
                <h3 className={style["number"]}>
                  {item.icon === NewMessage ? (
                    <CountUp
                      className={style["number"]}
                      start={0}
                      end={metrics?.totalSmsJobs}
                      duration={3}
                      separator=","
                    />
                  ) : item.icon === OTP ? (
                    <CountUp
                      className={style["number"]}
                      start={0}
                      end={metrics?.totalVoiceJobs}
                      duration={3}
                      separator=","
                    />
                  ) : (
                    <CountUp
                      className={style["number"]}
                      start={0}
                      end={metrics?.totalCustomers}
                      duration={3}
                      separator=","
                    />
                  )}
                </h3>
                <p className={style["text"]}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={style["team--section"]}>
          <h3>Our Team</h3>
          <p>Meet the Rockstars running things behind the scenes </p>

          <div className={style["team--section__members"]}>
            {members.map((member, index) => (
              <div className={style["team--member"]} key={index}>
                <img src={member.image} alt={member.name} />
                <p className={style["team--member__name"]}>{member.name}</p>
                <p className={style["team--member__position"]}>
                  {member.position}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SubscribeForm />
      <Footer />
    </>
  );
}
