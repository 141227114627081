/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout/DashboardLayout";
import Illustration from "../../../assets/images/userguide__illustration.svg";
// import copyIcon from "../assets/icons/Dashboard/copyIcon.svg";
import style from "./index.module.scss";

import useCopyToClipboard from "../../../Customhooks/useCopytoClipboard";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo } from "../../../redux/actions/authActions";
import { clearErrorMessage } from "../../../redux/actions/messageActions";
import dayjs from "dayjs";
import axios from "axios";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as paymentActions from "../../../redux/actions/paymentActions";
import Modal from "../../../components/Modal";

export default function Dashboard({ code }) {
  const [dashboardState, setState] = React.useState([]);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const authData = {
      username: JSON.parse(sessionStorage.getItem("msa_user_data"))
        .contact_person,
      key: JSON.parse(sessionStorage.getItem("msa_user_data")).key,
    };

    const token = sessionStorage.getItem("msa_jwt");
    const id = JSON.parse(sessionStorage.getItem("msa_user_data")).id;

    const clientInfo = new FormData();
    clientInfo.append("client_id", id);
    clientInfo.append("token", token);

    dispatch(getClientInfo(clientInfo));

    dispatch(paymentActions.getPurchaseHistory(clientInfo));

    setState(authData);

    // return () => {
    dispatch(clearErrorMessage());
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageTitle="Dashboard">
      <div className={style["main__wrapper"]}>
        <DashboardInfo
          dashboardState={dashboardState}
          userInfo={userInfo}
          code={code}
        />
        <CreditHistory />
        <UserGuide />
      </div>
    </Layout>
  );
}

const DashboardInfo = ({ dashboardState, userInfo }) => {
  const navigate = useNavigate();

  const [maskedApikey, displayApiKey] = React.useState(false);
  const [isCopied, handleCopy] = useCopyToClipboard(2000);

  const [senderIds, setSenderIds] = useState(null);

  const [isModalOpen, setIsModalopen] = useState(false);
  const [modalOpen, setModalopen] = useState(false);

  const [requestData, setRequestData] = useState({
    sender_id: "",
    description: "",
  });
  const [data, setData] = useState(null);
  const { token, authState } = useSelector((state) => state.auth);

  const config = {
    headers: {
      Authorization: "Bearer XYRQTlpabh3V6EsZ",
    },
  };

  useEffect(() => {
    if (data) {
      setIsModalopen(false);
      setModalopen(true);
    }
  }, [data]);

  useEffect(() => {
    const fetchSenderIDs = async () => {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/senderid/dev/client/summary`,
          {
            client_id: JSON.parse(sessionStorage.getItem("msa_user_data")).id,
            token: sessionStorage.getItem("msa_jwt"),
          },
          config
        );
        if (res.status === 200) {
          setSenderIds(res.data);
        } else {
          setSenderIds(res.data);
          // setMessages({...messages, voiceSurvey: "Error sending voice survey call"});
        }
      } catch (err) {
        setSenderIds({ error: "Error making request" });
        // setMessages({...messages, voiceSurvey: "Error sending voice survey call"});
      }
    };

    fetchSenderIDs();
  }, []);

  const handleCopyToClip = (code) => {
    if (!maskedApikey) {
      displayApiKey(true);
      handleCopy(code);
    } else {
      displayApiKey(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/senderid/dev/request`,
        {
          sender_id: requestData.sender_id,
          description: requestData.description,
          client_id: authState.id,
          token,
        },
        config
      );
      if (res.status) {
        setData(res.data);
      } else {
        setData(res.data);
        // setMessages({...messages, voiceSurvey: "Error sending voice survey call"});
      }
    } catch (err) {
      setData({ ...data, loading: false, error: "Error making request" });
      // setMessages({...messages, voiceSurvey: "Error sending voice survey call"});
    }
  };

  const handleClose = () => {
    setModalopen(!modalOpen);
    navigate("/user/sender-id");
  };

  return (
    <section className={style["dashboard__info"]}>
      <Modal isOpen={modalOpen} toggleModal={() => setModalopen(!modalOpen)}>
        <div className={style.top}>
          <h2>Your Request is Under Review</h2>
          <p>We will send you a confrimation mail of your request</p>
        </div>
        <form>
          <Button type="submit" className="large--filled" onClick={handleClose}>
            Ok
          </Button>
        </form>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        toggleModal={() => setIsModalopen(!isModalOpen)}
      >
        <div className={style.top}>
          <h2>Sender ID details</h2>
          <p>Pls enter your customized sender ID</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Sender ID</label>
            <input
              maxLength={11}
              required
              value={requestData.sender_id}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  sender_id: e.target.value,
                }))
              }
              type="text"
              placeholder="Raah Cosmetics"
            />
          </div>
          <div>
            <label>Description</label>
            <textarea
              required
              rows="5"
              type="text"
              placeholder="Raah Cosmetics"
              value={requestData.description}
              onChange={(e) =>
                setRequestData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>
          <Button type="submit" className="large--filled">
            {requestData.loading ? "Requesting..." : "Request"}
          </Button>
        </form>
      </Modal>
      <div className={style["dashboard__info--header"]}>
        <header>
          <h3>Hi {userInfo?.contact_person},</h3>
          <p>
            Welcome back to your Service Agent Dashboard. Check out all the
            resources you need to get started with your account.
          </p>
        </header>
      </div>

      <div className={style["dashboard__info--card"]}>
        <header>
          <h2>{userInfo?.airtime_balance}</h2>
          <span>Units</span>
        </header>
        <p>Credit balance</p>
      </div>
      <div className={style["dashboard__info--card"]}>
        <header>
          <h4
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              wordWrap: "break-word",
            }}
          >{!maskedApikey ? "***********" : dashboardState?.key.key}</h4>
        </header>

        <div className={style["dashboard__info--actionarea"]}>
          <span>API Key</span>
          <Button
            type="button"
            className="medium--filled"
            onClick={() => handleCopyToClip(dashboardState?.key.key)}
          >
            <span>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "white" }}
                  d="M10.5 6.44434H3C2.17275 6.44434 1.5 7.11709 1.5 7.94434V15.4443C1.5 16.2716 2.17275 16.9443 3 16.9443H10.5C11.3273 16.9443 12 16.2716 12 15.4443V7.94434C12 7.11709 11.3273 6.44434 10.5 6.44434Z"
                  fill="white"
                />
                <path
                  style={{ fill: "white" }}
                  d="M15 1.94434H7.5C7.10218 1.94434 6.72064 2.10237 6.43934 2.38368C6.15804 2.66498 6 3.04651 6 3.44434V4.94434H12C12.3978 4.94434 12.7794 5.10237 13.0607 5.38368C13.342 5.66498 13.5 6.04651 13.5 6.44434V12.4443H15C15.3978 12.4443 15.7794 12.2863 16.0607 12.005C16.342 11.7237 16.5 11.3422 16.5 10.9443V3.44434C16.5 3.04651 16.342 2.66498 16.0607 2.38368C15.7794 2.10237 15.3978 1.94434 15 1.94434Z"
                  fill="white"
                />
              </svg>
            </span>
            {isCopied ? "Copied" : !maskedApikey ? "Reveal and Copy" : "Hide"}
          </Button>
        </div>
      </div>
      <div className={style["dashboard__info--card"]}>
        <header>
          <h2>{senderIds && senderIds.total ? senderIds.total : 0}</h2>
          <span>Sender ID</span>
        </header>

        <div className={style["dashboard__info--actionarea"]}>
          <Button
            type="button"
            className="medium--filled"
            onClick={() => setIsModalopen(true)}
            style={{ marginTop: ".7em" }}
          >
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "white" }}
                  d="M16.5 2.25H1.5C0.675 2.25 0 2.925 0 3.75V14.25C0 15.075 0.675 15.75 1.5 15.75H16.5C17.325 15.75 17.9925 15.075 17.9925 14.25L18 3.75C18 2.925 17.325 2.25 16.5 2.25ZM16.5 14.25H1.5V3.75H16.5V14.25ZM15.75 4.5H10.5V8.25H15.75V4.5ZM15 6L13.125 7.3125L11.25 6V5.25L13.125 6.5625L15 5.25V6ZM6.75 9C7.9875 9 9 7.9875 9 6.75C9 5.5125 7.9875 4.5 6.75 4.5C5.5125 4.5 4.5 5.5125 4.5 6.75C4.5 7.9875 5.5125 9 6.75 9ZM6.75 6C7.1625 6 7.5 6.3375 7.5 6.75C7.5 7.1625 7.1625 7.5 6.75 7.5C6.3375 7.5 6 7.1625 6 6.75C6 6.3375 6.3375 6 6.75 6ZM11.25 12.4425C11.25 10.5675 8.2725 9.7575 6.75 9.7575C5.2275 9.7575 2.25 10.5675 2.25 12.4425V13.5H11.25V12.4425ZM4.11 12C4.665 11.625 5.775 11.25 6.75 11.25C7.725 11.25 8.8275 11.6175 9.39 12H4.11Z"
                  fill="#fff"
                />
              </svg>
            </span>
            Request Sender ID
          </Button>
        </div>
      </div>
    </section>
  );
};

const CreditHistory = () => {
  const purchaseHistory = useSelector(
    ({ payments }) => payments.purchase_history
  );
  dayjs.extend(localizedFormat);

  return (
    <section className={style["dashboard__credithistory"]}>
      <header>
        <h4>Transaction History</h4>
      </header>
      {purchaseHistory?.data.length > 0 ? (
        purchaseHistory?.data?.map((item) => {
          return (
            <div className={style["dashboard__credithistory--card"]}>
              <div>
                <h4>{item.purchase_source}</h4>
                <p>{dayjs(item.created_at).format("LLL")}</p>
              </div>

              <span>{item.amount_paid}</span>
            </div>
          );
        })
      ) : (
        <div>
          <h3>No purchase made.</h3>
          <p>Buy some units to get started</p>
        </div>
      )}
      {/* {} */}
    </section>
  );
};

const UserGuide = () => {
  const userguideList = [
    {
      title: "Step 1",
      instruction: "Top up your Service Agent Wallet to get started        ",

      route: "/wallet/credit",
    },
    {
      external: true,
      title: "Step 2",
      instruction: "Send OTP Tokens and Notification Alerts        ",
      route: "https://documentation.myserviceagent.net/?curl#otp-apis",
    },
    {
      title: "Step 3",
      instruction: "View Guide on Sending Business Messages        ",
      route: "/user/send-messages",
    },
  ];
  return (
    <section id="user" className={style["dashboard__userguide"]}>
      <header>
        <h4>User Guide</h4>
      </header>
      <div className={style["dashboard__userguide--card"]}>
        {userguideList.map((item, index) => {
          return (
            <div key={index * 10} className={style[`heading${index + 1}`]}>
              <h4>{item.title}</h4>
              <p>{item.instruction}</p>
              {item.external ? (
                <a
                  href={item.route}
                  style={{
                    color: "grey",
                    display: "inline-block",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  <p>Start »</p>
                </a>
              ) : (
                <Link
                  to={item.route}
                  style={{
                    color: "grey",
                    display: "inline-block",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  <p>Start »</p>
                </Link>
              )}
            </div>
          );
        })}

        {/* <NavLink to="/documentation">
          <p>Read Api Documentation</p>
        </NavLink> */}

        <div>
          <img src={Illustration} alt="girl stretching" />
        </div>
      </div>
    </section>
  );
};
